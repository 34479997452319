import children from './children.json';

function getHierarchicalData() {
    var data = {
        "id": 1,
        "name": "Animals",
        "type": "Root",
        "description": "A living organism that feeds on organic matter",
        "children": children
    };

    return data;
}

export default getHierarchicalData;

