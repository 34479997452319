import React from 'react';
import { BrowserRouter as Router, Routes, Route,Link } from 'react-router-dom';
import Home from './components/Home';
import BasicTree from "./components/BasicTree";
import getHierarchicalData from "./components/getHierarchicalData";
import "./components/styles.css";

function App() {
  let data = getHierarchicalData();
  return (
    <>
    <Router>
        <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/basic-tree">BasicTree</Link>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<BasicTree data={data}/>} />
        <Route path="/basic-tree"  element={<Home />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;