import React from 'react';
import { Tree } from 'react-d3-tree';

const treeData = {
    "name": "CEO",
    "children": [
      {
        "name": "Manager",
        "attributes": {
          "Department": "Production",
          "isFullTime": true,
          "weeklyHours": 80
        },
        "children": [
          {
            "name": "Foreman",
            "attributes": {
              "Department": "Fabrication"
            },
            "children": [
              {
                "name": "Workers"
              }
            ]
          },
          {
            "name": "Foreman",
            "attributes": {
              "Department": "Assembly"
            },
            "children": [
              {
                "name": "Workers"
              }
            ]
          }
        ]
      },
      {
        "name": "Manager",
        "attributes": {
          "Department": "Marketing",
          "isFullTime": true,
          "weeklyHours": 80
        },
        "children": [
          {
            "name": "Sales Officer",
            "attributes": {
              "Department": "A"
            },
            "children": [
              {
                "name": "Salespeople"
              }
            ]
          },
          {
            "name": "Sales Officer",
            "attributes": {
              "Department": "B"
            },
            "children": [
              {
                "name": "Salespeople"
              }
            ]
          }
        ]
      }
    ]
  };
  


function Home() {
    return (
        <div style={{ width: '100%', height: '1200px' ,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Tree data={treeData}
            orientation={"vertical"}
            translate={{ x: 600, y: 200 }}
            centeringTransitionDuration= {800}
            // translateX= {200}
            // translateY= {300}
            collapsible= {true}
            shouldCollapseNeighborNodes= {false}
            initialDepth= {1}
            zoomable= {true}
            draggable= {true}
            zoom= {1}
            scaleExtent= { {min: 0.1, max: 1} }
            separation = { {siblings: 2, nonSiblings: 2} }
            nodeSize = { {x: 150, y: 150 }}
            enableLegacyTransitions = {false}
            transitionDuration = {500}
             />
        </div>
    );
}

export default Home;