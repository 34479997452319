import { boxedTree } from 'd3-mitch-tree';
import 'd3-mitch-tree/dist/css/d3-mitch-tree-theme-default.min.css';
import 'd3-mitch-tree/dist/css/d3-mitch-tree.min.css';
import React, { useRef, useEffect } from 'react';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyComponent = (props) => {
    const visualisationRef = useRef(null);

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const generateMitchTree = () => {
            new boxedTree()
                .setData(props.data)
                .setAllowFocus(false)
                .setElement(visualisationRef.current)
                .setIdAccessor((data) => data.id)
                .setChildrenAccessor((data) => data.children)
                .setHeightWithoutMargins(2000)
                .setWidthWithoutMargins(15000)
                .setBodyDisplayTextAccessor((data) => data.description)
                .setTitleDisplayTextAccessor((data) => data.name)
                .setOrientation('topToBottom')
                .getNodeSettings()
                .setSizingMode('nodesize')
                .setVerticalSpacing(10)
                .setHorizontalSpacing(10)
                .back()
                .initialize();

        };

        generateMitchTree();
    }, [props.data]);

    const onClickHandler = () => {
        const visualisationNode = visualisationRef.current;
        window.scrollTo(0, 0);

        html2canvas(visualisationNode, { scrollY: -window.scrollY }).then((canvas) => {
            canvas.toBlob((blob) => {
                saveAs(blob, 'mitch_tree.png');
            });
        });
    };

    return (
        <div>
            <Button className='btn btn-primary' color='primary' onClick={onClickHandler}>
                Download as PNG
            </Button>
            <div ref={visualisationRef} id='visualisation' style={{ width: '20000px', height: '1000px' }}></div>
        </div>
    );
};

export default MyComponent;

















